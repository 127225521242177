import React from 'react';
import style from './allcasestudy.module.css';
// import caseStudy1 from '../../assets/casestudy/Case-study__image 1.png'
// import caseStudy2 from '../../assets/casestudy/Case-study__image 2.png'
// import caseStudy3 from '../../assets/casestudy/Case-study__image3.png'
import axios from 'axios';
import { useEffect } from 'react';


const AllCaseStudy = () => {

    const [CaseStudy, setCaseStudy] = React.useState([]);

    const Get_CaseStudy = () => {
        axios.get("https://api.brainbinaryinfotech.com/casestudy/", {
            headers: { 'content-type': 'multipart/form-data' }
        })
            .then((res) => {
                setCaseStudy(res.data.CASE_STUDIES);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        Get_CaseStudy()
    }, [])

    return (
        <>
            <div className={style.maindiv}>
                <div className='row' data-aos="zoom-in">
                    <div className={`heading headingborder mb-4`}>
                        Read Our Case Studies
                    </div>
                </div>
                <div className='row'>
                    {
                        CaseStudy.map((el, index) => {
                            return (
                                <div className='col-12 col-lg-4 col-sm-6 p-xl-4 p-3' key={index} data-aos="zoom-in">
                                    <div className={style.backcolor}>
                                        <div className={`card ${style.card}`}>
                                            <img src={el.image.url} className={`card-img-top ${style.cardimage}`} alt="..." />
                                            <div className="card-body">
                                                <h5 className={`card-title ${style.contentheading} my-2`}>
                                                    {el.content}
                                                </h5>
                                                <a href="/googlemap-flutter" className={`btn ${style.readmorebtn}`}>
                                                    Read more &nbsp; &nbsp; &nbsp;
                                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.172 7.49992L6.808 2.13592L8.222 0.721924L16 8.49992L8.222 16.2779L6.808 14.8639L12.172 9.49992H0V7.49992H12.172Z" fill="url(#paint0_linear_278_569)" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_278_569" x1="8" y1="0.721924" x2="8" y2="16.2779" gradientUnits="userSpaceOnUse">
                                                                <stop stopColor="#68B1D2" />
                                                                <stop offset="1" stopColor="#0B618E" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </>
    )
}

export default AllCaseStudy
