import style from './contactus.module.css'
import contactusimg from '../../assets/contactus/contactusimg.png'
import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import axios from 'axios';
import Swal from 'sweetalert2';

const GetQuotes_Schema = Yup.object().shape({
    FirstName: Yup.string()
        .required('First name is required !'),
    LastName: Yup.string()
        .required('Last name is required !'),
    Email: Yup.string()
        .email('Invalid email !')
        .required('Email is required !'),
    MobileNo: Yup.string()
        .required('Mobile no is required !'),
    ProjectDescription: Yup.string()
        .required('Project Description is required !'),
});


const ContactUs = () => {

    let confirm_get_quotes = () => {
        Swal.fire({
            title: "Good job !",
            text: "You're Response is Submitted !",
            icon: "success",
            confirmButtonColor: '#0b618e',
            confirmButtonText: 'OK , Got it !',
        });
    }

    return (
        <>
            <div className={`${style.maindiv} py-3`} id='contactus'>
                <div className={`row h-100 py-sm-5 py-4 px-xl-5 px-0`} data-aos="fade-up">
                    <div className="col col-xl-1 h-100"></div>
                    <div className={`col-12 col-xl-5 col-md-6`}>
                        <div className='position-relative'>
                            <div className={style.imagegradient}></div>
                            <img src={contactusimg} className={`${style.backimage}`} alt='contact us' />
                            <div className={style.contactinfo}>
                                <div className={`d-flex mt-2`}>
                                    <div className='me-3 pt-1'><svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12 30C14.6489 30 24 18.11 24 11.6667C24 5.22335 18.6274 0 12 0C5.37258 0 0 5.22335 0 11.6667C0 18.11 9.35114 30 12 30ZM12 16.6667C14.8403 16.6667 17.1428 14.4281 17.1428 11.6667C17.1428 8.90525 14.8403 6.66667 12 6.66667C9.15968 6.66667 6.85718 8.90525 6.85718 11.6667C6.85718 14.4281 9.15968 16.6667 12 16.6667Z" fill="white" />
                                    </svg></div>
                                    <div className=''>
                                        <p className={style.heading}>Address</p>
                                        <p className={style.content}>
                                            <a
                                                href="https://www.google.com/maps/search/?api=1&query=302%2C+Mahadev+Chowk%2C+Dhara+Arcade%2C+Maruti+Nandan+Society%2C+Mota+Varachha%2C+Surat%2C+Gujarat+394105"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                302, Mahadev Chowk, Dhara Arcade, Maruti Nandan Society, Mota Varachha, Surat, Gujarat 394105
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div className={`d-flex mt-sm-5 mt-2`}>
                                    <div className='me-3 pt-1'>
                                        <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M29.2858 17.1429V4.28571C29.2858 4.28571 16.2858 13.3571 15.0215 13.8279C13.7786 13.3786 0.714355 4.28571 0.714355 4.28571V17.1429C0.714355 18.9286 1.09293 19.2857 2.85721 19.2857H27.1429C28.9494 19.2857 29.2858 18.9721 29.2858 17.1429ZM29.2651 1.765C29.2651 0.464286 28.8858 0 27.1429 0H2.85721C1.06436 0 0.714355 0.557143 0.714355 1.85714L0.735784 2.05714C0.735784 2.05714 13.6422 10.9429 15.0215 11.4286C16.4786 10.8643 29.2858 1.85714 29.2858 1.85714L29.2651 1.765Z" fill="white" />
                                        </svg>
                                    </div>
                                    <div className=''>
                                        <p className={style.heading}>Email</p>
                                        <p className={`${style.content} my-sm-1 my-0`}>Sales:<a href="mailto:brainbinaryinfotech@gmail.com">brainbinaryinfotech@gmail.com</a></p>
                                        <p className={style.content}>HR:<a href="mailto:hrbrainbinaryinfotech@gmail.com">hrbrainbinaryinfotech@gmail.com</a></p>
                                    </div>
                                </div>
                                <div className={`d-flex mt-sm-5 mt-2`}>
                                    <div className='me-3 pt-1'>
                                        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.6952 14.1327L15.1259 14.6987C15.1259 14.6987 13.7726 16.0444 10.0789 12.3715C6.38515 8.69876 7.73837 7.35321 7.73837 7.35321L8.09687 6.99672C8.98012 6.11855 9.06337 4.70863 8.29275 3.67935L6.71657 1.57385C5.76285 0.299896 3.91995 0.131608 2.82681 1.21853L0.86481 3.1694C0.322785 3.70835 -0.0404398 4.40698 0.00361025 5.18201C0.116298 7.16477 1.01339 11.4309 6.0192 16.4082C11.3276 21.6865 16.3085 21.8962 18.3454 21.7064C18.9896 21.6464 19.5499 21.3182 20.0014 20.8692L21.7771 19.1037C22.9757 17.9119 22.6377 15.8686 21.1041 15.035L18.716 13.7367C17.709 13.1894 16.4822 13.3501 15.6952 14.1327Z" fill="white" />
                                        </svg>
                                    </div>
                                    <div className=''>
                                        <p className={style.heading}>Contact No.</p>
                                        <p className={style.content}>
                                            <a href="tel:+9108460484914">+91 084604 84914</a>
                                        </p>
                                    </div>
                                </div>
                                <div className={`d-flex mt-sm-5 mt-2`}>
                                    <div className='me-3 pt-1'>
                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M17.9412 17.5L11.7647 12.8676V5.14707H13.2353V12.1324L18.8235 16.3221L17.9412 17.5H17.9412ZM12.5 0C5.60736 0 0 5.60736 0 12.5C0 19.3912 5.60736 25 12.5 25C19.3912 25 25 19.3912 25 12.5C25 5.60736 19.3912 0 12.5 0Z" fill="white" />
                                        </svg>
                                    </div>
                                    <div className=''>
                                        <p className={style.heading}>Time</p>
                                        <p className={style.content}>Mon - Sat : 9 am to 6 pm</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-5 col-md-6 bg-white mt-4 m-md-0 px-3">
                        <h1 className={`${style.contactheading} px-4`}>
                            Get your free quote today
                        </h1>
                        <div>
                            <Formik
                                initialValues={{
                                    FirstName: '',
                                    LastName: '',
                                    Email: '',
                                    MobileNo: '',
                                    ProjectDescription: '',
                                }}
                                validationSchema={GetQuotes_Schema}
                                onSubmit={async (values, action) => {

                                    axios.post("https://api.brainbinaryinfotech.com/getquote/add", values)
                                        .then((res) => {
                                            action.resetForm();
                                            confirm_get_quotes()
                                        })
                                        .catch((err) => {
                                            alert(err.response.data.message)
                                        })

                                }}
                            >
                                <Form>
                                    <div className='d-flex w-100'>
                                        <div className={`w-100`}>
                                            <div className={style.inputwidth}>
                                                <div className='m-lg-3 m-2'>
                                                    <Field
                                                        type="text"
                                                        name="FirstName"
                                                        placeholder='First name'
                                                        className={`${style.inputfield} form-control`}
                                                    />
                                                    <ErrorMessage name="FirstName" component={'span'} className="text-danger" style={{ fontSize: "14px" }} />
                                                </div>
                                                <div className='m-lg-3 m-2'>
                                                    <Field
                                                        type="text"
                                                        name="LastName"
                                                        placeholder='Last name'
                                                        className={`${style.inputfield} form-control`}
                                                    />
                                                    <ErrorMessage name="LastName" component={'span'} className="text-danger" style={{ fontSize: "14px" }} />

                                                </div>
                                                <div className='m-lg-3 m-2'>
                                                    <Field
                                                        type="text"
                                                        name="Email"
                                                        placeholder='Email'
                                                        className={`${style.inputfield} form-control`}
                                                    />
                                                    <ErrorMessage name="Email" component={'span'} className="text-danger" style={{ fontSize: "14px" }} />

                                                </div>
                                                <div className='m-lg-3 m-2'>
                                                    <Field
                                                        type="text"
                                                        name="MobileNo"
                                                        placeholder='Mobile no'
                                                        className={`${style.inputfield} form-control`}
                                                        maxLength="10"
                                                    />
                                                    <ErrorMessage name="MobileNo" component={'span'} className="text-danger" style={{ fontSize: "14px" }} />
                                                </div>
                                                <div className='m-lg-3 m-2'>
                                                    <Field
                                                        name="ProjectDescription"
                                                        placeholder='Project description / Content'
                                                        className={`${style.inputfield} form-control`}
                                                        style={{ paddingBottom: "10px" }}
                                                    />
                                                    <ErrorMessage name="ProjectDescription" component={'span'} className="text-danger" style={{ fontSize: "14px" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center mt-1 pt-3'>
                                        <button type='submit' className={`btn ${style.submitbtn}`}>Send Message</button>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUs
