import React, { useEffect, useState } from 'react';
import './AdminLogin.css';
import brainbinarylogo from '../../assets/brainbinarylogo.png'
import { MdOutlineMailLock } from "react-icons/md";
import { TbPasswordUser } from "react-icons/tb";
import { MdOutlineLogin } from "react-icons/md";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Admin_Login_Schema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required'),
});


function AdminLogin() {

    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {

        let TOKEN = localStorage.getItem("ADMIN_TOKEN");

        if (TOKEN) {
            navigate("/admin/dashboard")
        }
        else {
            navigate("/admin/login")
        }

        AOS.init();

    }, [navigate])

    return (
        <div className="main-wrapper">
            <div className="wrapper" data-aos="zoom-in">
                <div className="logo">
                    <img src={brainbinarylogo} alt="BRAIN BINARY LOGO" />
                </div>
                <div className="text-center mt-4 name">
                    Brain Binary
                </div>

                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    validationSchema={Admin_Login_Schema}
                    onSubmit={async (values, action) => {

                        axios.post("https://api.brainbinaryinfotech.com/admin/login", values)
                            .then((res) => {
                                action.resetForm()
                                localStorage.setItem("ADMIN_TOKEN", res.data.TOKEN)
                                navigate("/admin/dashboard")
                                toast.success(res.data.message.toLocaleLowerCase())
                            })
                            .catch((err) => {
                                console.log(err);
                                toast.error(err.response.data.message.toLocaleLowerCase());
                            })
                    }}
                >
                    <Form>
                        <div className="p-3 mt-3">
                            <div className="form-field d-flex align-items-center">
                                <span className="mx-2 fs-5"> <MdOutlineMailLock /></span>
                                <Field type="email" name="email" id="email" placeholder="Email" autoComplete="off" />
                            </div>
                            <span> <ErrorMessage component={'span'} name='email' className='text-danger' /> </span>
                            <div className="form-field d-flex align-items-center mt-4">
                                <span className="mx-2 fs-5"> <TbPasswordUser /></span>
                                <Field type={showPassword ? "text" : "password"} name="password" id="password" placeholder="Password" />
                                <span className="ms-2 me-3 fs-5 pointer" onClick={handleShowPassword}>
                                    {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                                </span>
                            </div>
                            <span> <ErrorMessage component={'span'} name='password' className='text-danger' /> </span>
                            <button type='submit' className="btn mt-5 d-flex align-items-center justify-content-center">
                                <MdOutlineLogin className='me-2 fs-5' />
                                Login
                            </button>
                        </div >

                    </Form>
                </Formik>


            </div>
        </div>
    )
}

export default AdminLogin;