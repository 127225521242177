import React, { useState } from 'react'
import { useEffect } from 'react';
import axios from 'axios';
import { RiDeleteBin5Line } from "react-icons/ri";
// import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import LoderPageRound from '../Testing/LoderPageRound';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaEye } from "react-icons/fa";
import moment from 'moment';


function ApplyJobNow() {

    const [ApplyJobNow, setApplyJobNow] = React.useState([]);
    const [loader, Setloader] = useState(false);

    const Get_AppliedJobs = () => {
        axios.get("https://api.brainbinaryinfotech.com/applyjobnow/")
            .then((res) => {
                setApplyJobNow(res.data.JOB_DATA);
                Setloader(true)
            })
            .catch((err) => {
                console.log(err);
                Setloader(true)
            })
    }

    const Delete_AppliedJob = (_id) => {
        axios.delete("https://api.brainbinaryinfotech.com/applyjobnow/" + _id)
            .then((res) => {
                // toast.success(res.data.message)
                Get_AppliedJobs()
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const Delete_Confirmation = (_id) => {
        Swal.fire({
            title: "Are you sure ?",
            text: "You won't to delete this Applied Job / Position Data !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes , Delete It !"
        }).then((result) => {
            if (result.isConfirmed) {
                Delete_AppliedJob(_id)
                Swal.fire({
                    title: "Deleted!",
                    text: "Applied Job / Position Data has been deleted Sucessfully.",
                    icon: "success",
                    confirmButtonColor: "green",
                });
            }
        });

    }

    useEffect(() => {
        Get_AppliedJobs()
        AOS.init();
    }, [])

    if (!loader) {
        return <LoderPageRound />
    }



    return (
        <div data-aos="fade-up">

            <div className='px-2'>
                <h4 style={{ color: "#0B618E" }}>Applied Jobs / Positions </h4>
            </div>

            <div className='overflow-x-auto px-2'>
                <table className="mt-5 table table-striped" style={{ borderRadius: "10px", overflow: "hidden", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 6px 2px" }}>
                    <thead className='thead-dark'>
                        <tr>
                            <th scope="col" className='text-left' style={{ color: "#0B618E", fontWeight: "normal" }}> ID </th>
                            <th scope="col" className='text-left' style={{ color: "#0B618E", fontWeight: "normal", textWrap: "nowrap" }}> First Name </th>
                            <th scope="col" className='text-left' style={{ color: "#0B618E", fontWeight: "normal", textWrap: "nowrap" }}> Last Name </th>
                            <th scope="col" className='text-left' style={{ color: "#0B618E", fontWeight: "normal" }}> Email </th>
                            <th scope="col" className='text-left' style={{ color: "#0B618E", fontWeight: "normal" }}> Mobile No </th>
                            <th scope="col" className='text-left' style={{ color: "#0B618E", fontWeight: "normal" }}> City </th>
                            <th scope="col" className='text-left' style={{ color: "#0B618E", fontWeight: "normal" }}> Experience </th>
                            <th scope="col" className='text-left' style={{ color: "#0B618E", fontWeight: "normal" }}> Position </th>
                            <th scope="col" className='text-left' style={{ color: "#0B618E", fontWeight: "normal" }}> Joining </th>
                            <th scope="col" className='text-left' style={{ color: "#0B618E", fontWeight: "normal" }}> Actions </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ApplyJobNow.map((el, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <tr key={index}>
                                            <td className='text-left align-middle'>{index + 1}</td>
                                            <td className='text-left align-middle'> {el.FirstName}</td>
                                            <td className='text-left align-middle'> {el.LastName}</td>
                                            <td className='text-left align-middle'> {el.Email}</td>
                                            <td className='text-left align-middle'> {el.MobileNo}</td>
                                            <td className='text-left align-middle'> {el.City}</td>
                                            <td className='text-left align-middle' style={{ textWrap: "nowrap" }}> {el.Experience}</td>
                                            <td className='text-left align-middle' style={{ textWrap: "nowrap" }}> {el.Position}</td>
                                            <td className='text-left align-middle' style={{ textWrap: "nowrap" }}> {el.Joining}</td>
                                            <td className='text-center align-middle'>
                                                <RiDeleteBin5Line onClick={() => Delete_Confirmation(el._id)} className='pointer fs-5' style={{ color: "red" }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={12}>
                                                <ul className='mb-0'>
                                                    <li> Applied Date : {moment(el.createdAt).format('DD/MM/YYYY')} </li>
                                                    <li> Candidate applied Position : {el.Job_Information.JobTitle} </li>
                                                    <li> Candidate Experience : {el.Experience} </li>
                                                    <li> Candidate Message : {el.Messages} </li>
                                                    <li className='list-unstyled my-2'>
                                                        <a href={el.ResumeOrCv.url} className='text-decoration-none' rel="noreferrer" target='_blank'>
                                                            <button type="button" className="px-3 btn btn-sm d-flex align-items-center text-white " style={{ backgroundColor: "#0B618E" }}>
                                                                <FaEye className='fs-6 me-2' /> Resume / Cv
                                                            </button>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )
                            })
                        }

                    </tbody>
                </table>
            </div>

        </div>
    );
}

export default ApplyJobNow;