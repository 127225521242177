import React, { useEffect } from 'react'
import style from './home.module.css'
import homepageimg from '../../assets/home/homepageimg.png'
// import OurService from '../../components/ourservices/OurService'
import Experience from '../../components/expireance/Experience'
// import ClientSlider from '../../components/clientslider/ClientSlider'
import OurTechnologies from '../../components/ourTechnologies/OurTechnologies'
// import CaseStudy from '../../components/casestudy/CaseStudy'
// import BbInfotech from '../../components/bbinfotech/BbInfotech'
// import TestMonials from '../../components/testmonials/TestMonials'
import Blogs from '../../components/blogs/Blogs'
// import ContactUs from '../../components/contactus/ContactUs'
import roundspringimg from '../../assets/roundspring.png'
import SatisfiedClientsCounter from '../../components/satisfiedclients/SatisfiedClientsCounter'
import CompanyHistory from '../../components/companyhistory/CompanyHistory';
import OurCustomersLove from '../../components/ourcustomerslove/OurCustomersLove'
import RecentlyLaunched from '../../components/recentlylaunched/RecentlyLaunched';
import Haveanidea from '../../components/haveanidea/Haveanidea';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";

const Home = () => {

  useEffect(() => {
    AOS.init();

    const handleScroll = () => {
      AOS.refresh();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`${style.maindiv}`}>
      <div className={`${style.rowpadding} row`}>
        <div className="col-lg-7 col-12  pe-xl-5  pe-0 d-flex align-items-center position-relative" data-aos="zoom-in-right">
          <div className={style.wordgroup}>&nbsp;
            {/* <span className={style.software}>Software </span>
            <span className={style.development}>Development </span><br />
            <span className={style.company}>Company </span>
            <span className={style.and}> & </span>
            <span className={style.company}>IT </span>
            <span className={style.company}>Consultancy </span> */}
            <div>
              <h3 className='fw-bold'> Mobile Apps & Website Development </h3>
              <h3 className='fw-bold'>Specialist Company </h3>
            </div>
            <div className='mt-xl-4 mt-sm-3 mt-1'>
              <p className={style.content}>
                We serve offshore and dedicated resources for mobile apps and website development services worldwide, as well as deliver quality work on a period of time. Our main focus is to turn your ideas into reality with the success of the customer's business.
              </p>
            </div>
            <div>
              <Link to="/contact-us" className={`btn my-md-5 my-3 ${style.getquotebtn} px-3 py-2`}>
                Contact us
              </Link>
            </div>
          </div>
          <div className={style.springimage}>
            <img src={roundspringimg} alt="Rounded Spring" className='w-100 h-100' />
          </div>
        </div>
        <div className="col-lg-5 col-12  mt-md-0 mt-3 d-xl-inline d-flex justify-content-center" data-aos="zoom-in-left">
          <img src={homepageimg} alt="" className={`${style.homeimage}`} />
        </div>
      </div>
      <SatisfiedClientsCounter />
      <CompanyHistory />
      <OurCustomersLove />
      {/* <OurService /> */}
      <div className='position-relative'>
        <div className={style.springimage2}>
          <img src={roundspringimg} alt="Rounded Spring" className='w-100 h-100' />
        </div>
        <Experience />
      </div>
      {/* <ClientSlider /> */}
      <OurTechnologies />
      {/* <div className='position-relative'>
        <div className={style.springimage2}>
          <img src={roundspringimg} alt="Rounded Spring" className='w-100 h-100' />
        </div> */}
      {/* <CaseStudy /> */}
      <RecentlyLaunched />
      {/* </div> */}
      {/* <BbInfotech /> */}
      {/* <div className='position-relative'>
        <div className={style.springimage3}>
          <img src={roundspringimg} alt="Rounded Spring" className='w-100 h-100' />
        </div> */}
      {/* <TestMonials /> */}
      {/* </div> */}
      <Blogs />
      {/* <ContactUs /> */}
      <Haveanidea />
    </div>
  )
}

export default Home;