import React from 'react'
import style from './statmanagementflutter.module.css'
import devloper from '../../assets/casestudy/Case-study__image 1.png'
import statmanagement from '../../assets/statmanagementflutter/statmanagement.png'
import mobexpakege from '../../assets/statmanagementflutter/mobxpakege.png'
import mobexpakege2 from '../../assets/statmanagementflutter/mobexpakage2.png'
import observables from '../../assets/statmanagementflutter/Observables.png'
import actions from '../../assets/statmanagementflutter/Actions.png'
import statmanagement2 from '../../assets/statmanagementflutter/statmanagement1.png'
import statmanagementgif from '../../assets/statmanagementflutter/unnamed.gif'

const StatManagementFlutter = () => {
  return (
    <>
      <div className={`${style.maindiv} container`}>
        <div className={`${style.heading} ${style.headingborder}`} data-aos="fade-right" data-aos-animation="3000">
          State Management of MobX in Flutter
        </div>
        <div className='d-flex mt-5 '>
          <div className={style.devloperinfo}>
            <img src={devloper} className={`${style.devloperimg}`} alt="Devloper" />
            <div className='ms-3'>
              <p className={style.content}>Niyati Brainbinary</p>
              <p className={style.devloperrole}>Flutter Devloper</p>
            </div>
          </div>
          <div className='d-flex align-items-center ms-3'>
            <p className={style.content}>March 12, 2024</p>
          </div>
        </div>
        <div className='mt-5'>
          <img src={statmanagement} className={`${style.googlemapimage}`} alt="Statamanagement" data-aos="zoom-in" />
        </div>
        <div className={`${style.headingcontent} ${style.headingborder} mt-3`} data-aos="fade-right" data-aos-animation="3000">
          Introduction
        </div>
        <div className='mt-3'>
          <p className={style.blogcontent}>MobX is a Battle tested state management library Transparently Functional Reactive programming (TFRP). The design principle is very simple:Anything that can be derived from the Application state, should be derived Automatically : UI, data serialisation, server communication, etc.
          </p>
        </div>
        <div className={`${style.headingcontent} ${style.headingborder} mt-3`} data-aos="fade-right" data-aos-animation="3000">
          What is Mobx?
        </div>
        <div className='mt-3'>
          <p className={style.blogcontent}>Mobx is a state-management library that makes it simple to connect the reactive data of your application with the UI.
          </p>
        </div>
        <div className={`${style.headingcontent} ${style.headingborder} mt-3`} data-aos="fade-right" data-aos-animation="3000">
          MobX for the Dart language
        </div>
        <div className='mt-3' >
          <ul className={style.listcontent}>
            <li>This wiring is completely automatic and feels very natural. As the application developer, you focus purely on what reactive data needs to be consumed in the UI (and elsewhere) without worrying about keeping the two in sync.</li>
            <li>It’s not really magic but it does have some smarts around what is being consumed (observables) and where (reactions), and automatically tracks it for you. When the observables change, all reactions are re-run. What’s interesting is that these reactions can be anything from a simple console log, a network call to re-rendering the UI.
            </li>
            <li>MobX has been a very effective library for the JavaScript apps and this port to the Dart language aims to bring the same levels of productivity.</li>
          </ul>
        </div>
        <div className={`${style.headingcontent} ${style.headingborder} mt-3`} data-aos="fade-right" data-aos-animation="3000">
          MobX packages:
        </div>
        <div className='mt-3'>
          <p className={style.blogcontent}> The project open in your IDE, navigate to your /pubspec.yaml file to add the dependencies. Replace the dependencies section with this snippet: </p>
        </div>
        <div className='mt-3'>
          <img src={mobexpakege} className={style.firebaseimage} alt='Mobx-Packege' />
        </div>
        <div className='mt-3'>
          <p className={style.blogcontent}> Then replace the dev_dependencies section with this snippet of code: </p>
        </div>
        <div className='mt-3'>
          <img src={mobexpakege2} className={style.firebaseimage} alt='Mobx-Packege' />
        </div>
        <div className={`${style.headingcontent} ${style.headingborder} mt-3`} data-aos="fade-right" data-aos-animation="3000">
          Concept of Mobx:
        </div>
        <div className='mt-3'>
          <p className={style.blogcontent}>There are main three concept of MobX.</p>
        </div>
        <div className='mt-3'>
          <ol className={style.listcontent}>
            <li>Observables</li>
            <li> Actions</li>
            <li> Reactions</li>
          </ol>
        </div>
        <div className='mt-5 text-center'>
          <img src={statmanagement2} className={style.firebaseimage} alt='Firebase' data-aos="zoom-in" />
        </div>
        <div className='mt-5'>
          <ol className={style.listcontent}>
            <li className='h4 text-black'><span className={`${style.subheading} `}>Observables</span>
              <div className='mt-3'>
                <p className={style.blogcontent}>The variables of store class whose state can be watchable. They can be simple scalars or complex object tree. We have to annotate the data with @observable.</p>
              </div>
              <div className='mt-3'>
                <img src={observables} className={style.firebaseimage} alt='Mobx-Packege' />
              </div>
            </li>
            <li className='h4 text-black'><span className={`${style.subheading} `}>Actions:</span>
              <div className='mt-3'>
                <p className={style.blogcontent}>The methods of store class which are mutate observables. We have to annotate them with @action.</p>
              </div>
              <div className='mt-3'>
                <img src={actions} className={style.firebaseimage} alt='Mobx-Packege' />
              </div>
            </li>
            <li className='h4 text-black'><span className={`${style.subheading} `}>Reactions</span>
              <div className='mt-3'>
                <p className={style.blogcontent}>As you understand from its name, reactions are pieces that listen to observables and reacts to every change made on them.</p>
                <p className={`${style.blogcontent} mt-3`}>To see changes in observable variables at UI, we need to use flutter_mobx library. That library has the Observer widget. We have to wrap our observable variable with Observer widget to see changes in that variable.
                </p>
              </div>
            </li>
          </ol>
        </div>
        <div className={`${style.subheading} mt-3`}>
          Example of Counter app using Mobx state management:<br />
          ={`>`} Mobx controller:
        </div>
        <div className='mt-3'>
          <p className={style.content} dangerouslySetInnerHTML={{
            __html: `
                    <code>
                    import 'package:mobx/mobx.dart';<br />
                    <br />
                    part 'counter.g.dart';<br />
                    <br />
                    class Counter = CounterBase with _$Counter;<br />
                    <br />
                    abstract class CounterBase with Store {<br />
                     &nbsp; @observable<br />
                     &nbsp; int number= 0;<br />
                      <br />
                      &nbsp; @action<br />
                      &nbsp; void increment() {<br />
                      &nbsp; number++;<br />
                      &nbsp; }<br />
                    }<br />
                    
                    </code>
                   `
          }} />
        </div>

        <div className={`${style.subheading} mt-3`}>
          ={`>`} Connect the widget:
        </div>
        <div>
          <p className={style.blogcontent}>final Counter counter = Counter();</p>
        </div>
        <div className={`${style.subheading} mt-3`}>
          ={`>`} How to use Observer widget:
        </div>
        <div>
          <p className={style.blogcontent}>To make the count automatically update on changes, we wrap the Text widget inside an Observer widget. Any observable that gets used in the builder function of Observer is automatically tracked. When it changes, Observer re-renders by calling the builder again.
          </p>
        </div>
        <div className='mt-3'>
          <p className={style.content} dangerouslySetInnerHTML={{
            __html: `
            <code>
              Observer(<br />
              &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; builder: (_) => Text(<br />
              &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp; '\${counter.number}',<br />
              &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp;    style: const TextStyle(fontSize: 40),<br />
              &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp;  )),<br />
            </code>
               `
          }} />
        </div>
        <div className={`${style.subheading} mt-3`}>
          ={`>`} Implement of Code:
        </div>
        <div className='mt-3'>
          <p className={style.content} dangerouslySetInnerHTML={{
            __html: `
                 <code>
                 class CounterExample extends StatefulWidget {<br />
                 &nbsp; const CounterExample();<br />
                   <br />
                   &nbsp;  @override<br />
                   &nbsp;CounterExampleState createState() => CounterExampleState();<br />
                 }<br />
                 <br />
                 class CounterExampleState extends State<CounterExample> {<br />
                 &nbsp; final Counter counter = Counter();<br />
                   <br />
                   &nbsp; @override<br />
                   &nbsp; Widget build(BuildContext context) => Scaffold(<br />
                   &nbsp;&nbsp; appBar: AppBar(<br />
                   &nbsp;&nbsp;&nbsp;  backgroundColor: Colors.blue,<br />
                   &nbsp;&nbsp;&nbsp;   title: const Text('MobX Counter'),<br />
                   &nbsp;&nbsp;  ),<br />
                   &nbsp;&nbsp; body: Center(<br />
                   &nbsp;&nbsp;&nbsp; child: Column(<br />
                   &nbsp;&nbsp;&nbsp;&nbsp;   mainAxisAlignment: MainAxisAlignment.center,<br />
                   &nbsp;&nbsp;&nbsp;&nbsp;    children:[<br />
                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;     const Text(<br />
                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;       'Count of value : ',<br />
                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    ),<br />
                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   Observer(<br />
                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    builder: (_) => Text(<br />
                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  '\${counter.number}',<br />
                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;           style: const TextStyle(fontSize: 40),<br />
                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;     )),<br />
                   &nbsp;&nbsp;&nbsp; ],<br />
                   &nbsp; &nbsp;    ),<br />
                   &nbsp;  ),<br />
                   &nbsp;  floatingActionButton: FloatingActionButton(<br />
                   &nbsp; &nbsp;     onPressed: counter.increment,<br />
                   &nbsp; &nbsp;       tooltip: 'Increment',<br />
                   &nbsp; &nbsp;       child: const Icon(Icons.add),<br />
                   &nbsp;   ),<br />
                   &nbsp;  );<br />
                 }<br />  
                 </code>
             `
          }} />
        </div>
        <div className='mt-5 text-center'>
          <img src={statmanagementgif} className={style.gifimage} alt='Google map' data-aos="zoom-in" style={{ borderRadius: "40px", border: "3px solid black" }} />
        </div>
      </div>
    </>
  )
}

export default StatManagementFlutter
