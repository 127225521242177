import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { LuAlignJustify } from "react-icons/lu";
import brainbinarylogo from '../../assets/brainbinarylogo.png'
import './AdminPanel.css';
import Accordion from 'react-bootstrap/Accordion';
import { MdOutlineLogout } from "react-icons/md";
import ListGroup from 'react-bootstrap/ListGroup';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function AdminPanel() {

    const [show, setShow] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const navigate = useNavigate();

    const handleClose = () => {
        setShow(false);
        setSidebarOpen(false);
    };
    const handleShow = () => {
        setShow(true);
        setSidebarOpen(true);
    };

    const handleResize = () => {
        if (window.innerWidth < 768) { // sm size
            setShow(false);
            setSidebarOpen(false);
        } else if (window.innerWidth < 992) { // md size
            setShow(false);
            setSidebarOpen(false);
        } else {
            setShow(true);
            setSidebarOpen(true);
        }
    };

    window.addEventListener('resize', handleResize);

    const LOG_OUT = () => {

        if (localStorage.getItem("ADMIN_TOKEN")) {
            localStorage.removeItem("ADMIN_TOKEN");
            navigate("/admin/login")
            toast.success("LOGGED OUT SUCESSFULLY !")
        }

    }

    return (
        <div>
            <Navbar expand="lg" className={`border-bottom border-2 position-sticky top-0`} style={{ backgroundColor: "white", zIndex: 99 }}>
                <Container fluid className='justify-content-between'>
                    <div className='d-flex align-items-center'>
                        {
                            sidebarOpen ? (
                                <LuAlignJustify className='pointer mx-3' onClick={handleClose} style={{ height: "30px", width: "30px", color: "#0B618E" }} />
                            ) : (
                                <LuAlignJustify className='pointer mx-3' onClick={handleShow} style={{ height: "30px", width: "30px", color: "#0B618E" }} />
                            )
                        }
                        <Navbar.Brand href="/admin/dashboard" className="me-auto d-flex align-items-center p-0">
                            <div>
                                <img src={brainbinarylogo} alt="ERROR 404" style={{ height: "50px" }} />
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="140" height="25" viewBox="0 0 153 28" fill="none">
                                    <path d="M6.29071 14.3679H0V0H5.59215C6.56359 0 7.40405 0.11643 8.10989 0.349284C8.81573 0.582139 9.33965 0.898672 9.68166 1.29525C10.3402 2.03384 10.6676 2.87066 10.6676 3.80208C10.6676 4.92633 10.3111 5.76315 9.59797 6.3089C9.33601 6.50173 9.15773 6.6218 9.06314 6.6691C8.96854 6.7164 8.79754 6.79644 8.55013 6.90559C9.44152 7.09842 10.151 7.49864 10.6786 8.10625C11.2061 8.71749 11.4681 9.47427 11.4681 10.3766C11.4681 11.3771 11.1261 12.2612 10.4421 13.0289C9.63072 13.9203 8.24815 14.3679 6.29071 14.3679ZM2.42678 5.95962H5.50847C7.26215 5.95962 8.139 5.36293 8.139 4.17319C8.139 3.48918 7.92797 2.99437 7.50228 2.69238C7.0766 2.3904 6.41805 2.24123 5.5303 2.24123H2.42678V5.95962ZM2.42678 12.1266H6.22886C7.12026 12.1266 7.80063 11.9847 8.27361 11.7046C8.7466 11.4244 8.98309 10.8932 8.98309 10.111C8.98309 8.83756 7.96072 8.20085 5.9196 8.20085H2.42678V12.1266Z" fill="#0B618E" />
                                    <path d="M25.9779 4.66437C25.9779 7.03658 24.9446 8.5283 22.8743 9.14682L26.6364 14.3679H23.5547L20.1237 9.53612H16.9365V14.3679H14.5098V0H19.8545C22.0484 0 23.6166 0.371113 24.5625 1.1097C25.5049 1.85192 25.9779 3.03439 25.9779 4.66437ZM22.7543 6.70184C23.2491 6.27616 23.4929 5.59579 23.4929 4.65709C23.4929 3.7184 23.2382 3.07441 22.7324 2.72513C22.2267 2.37585 21.3208 2.2012 20.0182 2.2012H16.9365V7.33856H19.9564C21.328 7.33856 22.2595 7.12389 22.7543 6.70184Z" fill="#0B618E" />
                                    <path d="M31.5081 11.1006L30.071 14.3679H27.4805L33.8112 0H36.4017L42.7324 14.3679H40.1419L38.7048 11.1006H31.5081ZM37.7152 8.85939L35.1065 2.93979L32.4978 8.85939H37.7152Z" fill="#0B618E" />
                                    <path d="M44.7227 0H47.1494V14.3679H44.7227V0Z" fill="#0B618E" />
                                    <path d="M61.9065 0H64.3333V14.3679H61.7027L53.5637 3.88576V14.3679H51.137V0H53.5637L61.9101 10.7295V0H61.9065Z" fill="#0B618E" />
                                    <path d="M80.2402 14.3679H73.9495V0H79.5416C80.513 0 81.3535 0.11643 82.0593 0.349284C82.7652 0.582139 83.2891 0.898672 83.6311 1.29525C84.2897 2.03384 84.6171 2.87066 84.6171 3.80208C84.6171 4.92633 84.2606 5.76315 83.5474 6.3089C83.2855 6.50173 83.1072 6.6218 83.0126 6.6691C82.918 6.7164 82.747 6.79644 82.4996 6.90559C83.391 7.09842 84.1005 7.49864 84.628 8.10625C85.1556 8.71749 85.4176 9.47427 85.4176 10.3766C85.4176 11.3771 85.0755 12.2612 84.3915 13.0289C83.5838 13.9203 82.1976 14.3679 80.2402 14.3679ZM76.3762 5.95962H79.4579C81.2116 5.95962 82.0885 5.36293 82.0885 4.17319C82.0885 3.48918 81.8774 2.99437 81.4517 2.69238C81.0261 2.3904 80.3675 2.24123 79.4798 2.24123H76.3762V5.95962ZM76.3762 12.1266H80.1783C81.0697 12.1266 81.7501 11.9847 82.2231 11.7046C82.6961 11.4244 82.9326 10.8932 82.9326 10.111C82.9326 8.83756 81.9102 8.20085 79.8691 8.20085H76.3762V12.1266Z" fill="#0B618E" />
                                    <path d="M88.4629 0H90.8897V14.3679H88.4629V0Z" fill="#0B618E" />
                                    <path d="M105.643 0H108.07V14.3679H105.439L97.3004 3.88576V14.3679H94.8737V0H97.3004L105.647 10.7295V0H105.643Z" fill="#0B618E" />
                                    <path d="M114.091 11.1006L112.654 14.3679H110.064L116.395 0H118.985L125.316 14.3679H122.725L121.288 11.1006H114.091ZM120.299 8.85939L117.69 2.93979L115.081 8.85939H120.299Z" fill="#0B618E" />
                                    <path d="M138.774 4.66437C138.774 7.03658 137.741 8.5283 135.67 9.14682L139.433 14.3679H136.351L132.92 9.53612H129.733V14.3679H127.306V0H132.651C134.845 0 136.413 0.371113 137.359 1.1097C138.305 1.84828 138.774 3.03439 138.774 4.66437ZM135.55 6.70184C136.045 6.27616 136.289 5.59579 136.289 4.65709C136.289 3.7184 136.034 3.07441 135.529 2.72513C135.023 2.37585 134.117 2.2012 132.814 2.2012H129.733V7.33856H132.753C134.124 7.33856 135.056 7.12389 135.55 6.70184Z" fill="#0B618E" />
                                    <path d="M147.655 14.3679H145.188V8.69567L139.844 0H142.496L146.422 6.53448L150.348 0H153L147.655 8.69567V14.3679Z" fill="#0B618E" />
                                    <path d="M0.0472412 21.2383H1.07326V27.3107H0.0472412V21.2383Z" fill="#6EB7D7" />
                                    <path d="M22.0811 21.2383H23.1071V27.3107H21.9938L18.5519 22.8792V27.3107H17.5259V21.2383H18.5519L22.0811 25.7753V21.2383Z" fill="#6EB7D7" />
                                    <path d="M40.5858 22.1952V23.8615H43.2782V24.8075H40.5858V27.3107H39.5598V21.2383H43.6093L43.602 22.1952H40.5858Z" fill="#6EB7D7" />
                                    <path d="M64.6644 26.4744C64.0496 27.0747 63.2928 27.3731 62.3868 27.3731C61.4809 27.3731 60.7241 27.0747 60.1092 26.4744C59.4943 25.8741 59.1887 25.1282 59.1887 24.2332C59.1887 23.3381 59.4943 22.5923 60.1092 21.9919C60.7241 21.3916 61.4809 21.0933 62.3868 21.0933C63.2928 21.0933 64.0496 21.3916 64.6644 21.9919C65.2793 22.5923 65.5849 23.3381 65.5849 24.2332C65.5849 25.1246 65.2757 25.8741 64.6644 26.4744ZM63.9113 22.6723C63.4965 22.243 62.9872 22.0283 62.3868 22.0283C61.7829 22.0283 61.2771 22.243 60.8624 22.6723C60.4476 23.1016 60.2402 23.6219 60.2402 24.2332C60.2402 24.8444 60.4476 25.3647 60.8624 25.794C61.2771 26.2233 61.7829 26.438 62.3868 26.438C62.9908 26.438 63.4965 26.2233 63.9113 25.794C64.3261 25.3647 64.5335 24.8444 64.5335 24.2332C64.5335 23.6183 64.3261 23.1016 63.9113 22.6723Z" fill="#6EB7D7" />
                                    <path d="M83.7766 22.177V27.3107H82.7506V22.177H80.9095V21.2383H85.6176V22.177H83.7766Z" fill="#6EB7D7" />
                                    <path d="M105.767 21.2383V22.2024H102.525V23.8106H105.436V24.7238H102.525V26.3502H105.869V27.3143H101.499V21.2419H105.767V21.2383Z" fill="#6EB7D7" />
                                    <path d="M124.726 26.3834C125.079 26.3834 125.385 26.3252 125.64 26.2052C125.894 26.0851 126.16 25.8959 126.44 25.6376L127.102 26.3143C126.458 27.0274 125.68 27.384 124.759 27.384C123.842 27.384 123.078 27.0893 122.474 26.4962C121.87 25.9068 121.565 25.1573 121.565 24.255C121.565 23.3527 121.874 22.5995 122.489 21.9956C123.107 21.3916 123.886 21.0933 124.825 21.0933C125.767 21.0933 126.553 21.4426 127.182 22.1375L126.531 22.8506C126.24 22.5704 125.967 22.3776 125.709 22.2685C125.45 22.1593 125.148 22.1047 124.799 22.1047C124.184 22.1047 123.671 22.3048 123.253 22.7014C122.834 23.098 122.627 23.6037 122.627 24.2222C122.627 24.8408 122.834 25.3538 123.249 25.7685C123.668 26.176 124.159 26.3834 124.726 26.3834Z" fill="#6EB7D7" />
                                    <path d="M143.122 27.3107V21.2383H144.148V23.8724H147.164V21.2383H148.19V27.3107H147.164V24.8257H144.148V27.3107H143.122Z" fill="#6EB7D7" />
                                </svg>
                            </div>
                        </Navbar.Brand>

                    </div>
                    <span>
                        <MdOutlineLogout onClick={() => LOG_OUT()} className='me-2 Logout fs-4 pointer' style={{ color: "#0B618E" }} />
                    </span>
                </Container>
            </Navbar>
            <div className={`${sidebarOpen ? 'sidebar-open' : 'sidebar-close'}`} style={{ padding: "30px 30px 30px 30px" }}>
                <Outlet />
            </div>
            <Offcanvas show={show} data-bs-scroll="true" scroll={true} onHide={handleClose} placement="start" backdrop={false} style={{ width: "275px", marginTop: "68px" }} className=" shadow-sm border-end border-2 sidebar-close" data-bs-backdrop="false">
                <Offcanvas.Body className='px-4 py-4'>
                    <Accordion>
                        <Accordion.Item eventKey="0" className='mb-2 custom-accordion shadow-sm'>
                            <Link to={"/admin/dashboard"} className='text-decoration-none'>
                                <Accordion.Header className='no-toggle-icon'>
                                    <h6 className='mb-0'>  Dashboard </h6>
                                </Accordion.Header>
                            </Link>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1" className='mb-2 custom-accordion shadow-sm' >
                            <Accordion.Header>
                                <h6 className='mb-0'> Our Technologies </h6>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <ListGroup as="ul" className='listGroupDesign'>
                                        <Link to={"/admin/ourtechnologies/categories"} className='text-decoration-none'>
                                            <ListGroup.Item as="li" className='pointer rounded-top-4 listGroupitemDesign shadow-sm'>
                                                <h6 className='mb-0'>  Categories </h6>
                                            </ListGroup.Item>
                                        </Link>
                                        <Link to={"/admin/ourtechnologies/items"} className='text-decoration-none'>
                                            <ListGroup.Item as="li" className='pointer rounded-bottom-4 listGroupitemDesign shadow-sm'>
                                                <h6 className='mb-0'>  Items </h6>
                                            </ListGroup.Item>
                                        </Link>
                                    </ListGroup>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2" className='mb-2 custom-accordion shadow-sm'>
                            <Link to={"/admin/getquote"} className='text-decoration-none'>
                                <Accordion.Header className='no-toggle-icon'>
                                    <h6 className='mb-0'> Get Quotes </h6>
                                </Accordion.Header>
                            </Link>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3" className='mb-2 custom-accordion shadow-sm'>
                            <Link to={"/admin/blogs"} className='text-decoration-none'>
                                <Accordion.Header className='no-toggle-icon'>
                                    <h6 className='mb-0'> Blogs </h6>
                                </Accordion.Header>
                            </Link>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4" className='mb-2 custom-accordion shadow-sm'>
                            <Link to={"/admin/casestudy"} className='text-decoration-none'>
                                <Accordion.Header className='no-toggle-icon'>
                                    <h6 className='mb-0'> Case Study </h6>
                                </Accordion.Header>
                            </Link>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5" className='mb-2 custom-accordion shadow-sm'>
                            <Link to={"/admin/companyhistory"} className='text-decoration-none'>
                                <Accordion.Header className='no-toggle-icon'>
                                    <h6 className='mb-0'> Company History </h6>
                                </Accordion.Header>
                            </Link>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6" className='mb-2 custom-accordion shadow-sm' >
                            <Accordion.Header>
                                <h6 className='mb-0'> Client / Customer </h6>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <ListGroup as="ul" className='listGroupDesign'>
                                        <Link to={"/admin/clientscustomers/satisfiedclientcount"} className='text-decoration-none'>
                                            <ListGroup.Item as="li" className='pointer rounded-top-4 listGroupitemDesign shadow-sm'>
                                                <h6 className='mb-0'>  Satisfied Clients Count </h6>
                                            </ListGroup.Item>
                                        </Link>
                                        <Link to={"/admin/clientscustomers/satisfiedclientimage"} className='text-decoration-none'>
                                            <ListGroup.Item as="li" className='pointer rounded-bottom-4 listGroupitemDesign shadow-sm'>
                                                <h6 className='mb-0'>  Satisfied Client Img (Slider) </h6>
                                            </ListGroup.Item>
                                        </Link>
                                        <Link to={"/admin/clientscustomers/ourcustomerlove"} className='text-decoration-none'>
                                            <ListGroup.Item as="li" className='pointer rounded-bottom-4 listGroupitemDesign shadow-sm'>
                                                <h6 className='mb-0'> Our Customers Love (Slider) </h6>
                                            </ListGroup.Item>
                                        </Link>
                                    </ListGroup>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="7" className='mb-2 custom-accordion shadow-sm'>
                            <Link to={"/admin/currentopenings"} className='text-decoration-none'>
                                <Accordion.Header className='no-toggle-icon'>
                                    <h6 className='mb-0'> Current Openings </h6>
                                </Accordion.Header>
                            </Link>
                        </Accordion.Item>

                        <Accordion.Item eventKey="8" className='mb-2 custom-accordion shadow-sm'>
                            <Link to={"/admin/applyjobnow"} className='text-decoration-none'>
                                <Accordion.Header className='no-toggle-icon'>
                                    <h6 className='mb-0'> Applied Job / Position </h6>
                                </Accordion.Header>
                            </Link>
                        </Accordion.Item>

                        <Accordion.Item eventKey="9" className='mb-2 custom-accordion shadow-sm'>
                            <Link to={"/admin/ourservice"} className='text-decoration-none'>
                                <Accordion.Header className='no-toggle-icon'>
                                    <h6 className='mb-0'> Our Services </h6>
                                </Accordion.Header>
                            </Link>
                        </Accordion.Item>

                        <Accordion.Item eventKey="10" className='mb-2 custom-accordion shadow-sm'>
                            <Link to={"/admin/recentlylaunched"} className='text-decoration-none'>
                                <Accordion.Header className='no-toggle-icon'>
                                    <h6 className='mb-0'> Recently Launched </h6>
                                </Accordion.Header>
                            </Link>
                        </Accordion.Item>

                    </Accordion>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default AdminPanel;