import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { toast } from 'react-toastify';
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { FaRegSave } from "react-icons/fa";
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import moment from 'moment';
import Swal from 'sweetalert2';
import LoderPageRound from '../Testing/LoderPageRound';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaRegImage } from "react-icons/fa";
import { IoCheckmarkDoneSharp } from "react-icons/io5";



const ClientImage_Schema = Yup.object().shape({
    image: Yup.mixed()
        .required('Plese add Client Image it is required !'),
});


function SetisfiedClientimage() {

    const [show, setShow] = useState(false);
    const [ClientImage, setClientImage] = React.useState([]);
    const [intialvalues, setintialvalues] = React.useState({ image: '' });
    const [Eid, setEid] = React.useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [loader, Setloader] = useState(false);
    const [TimeingLoader, setTimeingLoader] = useState(false);


    const handleShow = () => {
        setShow(true);
    }
    const handleClose = () => {
        setShow(false)
        setintialvalues({ image: '' })
        setImagePreview(null)
        setEid(null)
    }

    const Get_ClientsImage = () => {
        axios.get("https://api.brainbinaryinfotech.com/satisfiedclientimages/", {
            headers: { 'content-type': 'multipart/form-data' }
        })
            .then((res) => {
                setClientImage(res.data.CLIENT_IMAGES);
                Setloader(true)
            })
            .catch((err) => {
                console.log(err);
                Setloader(true)
            })
    }

    const Delete_ClientImage = (_id) => {

        setTimeingLoader(true)

        axios.delete("https://api.brainbinaryinfotech.com/satisfiedclientimages/" + _id, {
            headers: { 'content-type': 'multipart/form-data' }
        })
            .then((res) => {
                // toast.success(res.data.message.toLowerCase())
                Get_ClientsImage()
                setTimeingLoader(false)
            })
            .catch((err) => {
                console.log(err);
                setTimeingLoader(false)
            })
    }

    const Delete_Confirmation = (_id) => {

        Swal.fire({
            title: "Are you sure ?",
            text: "You won't to delete this Client's Image !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes , Delete It !"
        }).then((result) => {
            if (result.isConfirmed) {
                Delete_ClientImage(_id)

                setTimeout(() => {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Client's Image has been deleted Sucessfully.",
                        icon: "success",
                        confirmButtonColor: "green",
                    });
                }, 1200);
            }
        });

    }


    const Edit_ClientImage = (value, _id) => {

        setintialvalues({
            image: value.image,
            status: value.status
        })
        setEid(_id)
        handleShow()
    }

    useEffect(() => {
        AOS.init();
        Get_ClientsImage()
    }, [])


    if (!loader || TimeingLoader) {
        return <LoderPageRound />;
    }


    return (
        <div data-aos="fade-up">
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className="d-flex">
                            <FaRegImage className='fs-5 me-2' />
                            <h6> {Eid ? 'Update' : 'Add'} Client's Img </h6>
                        </div>
                    </Modal.Title>
                </Modal.Header>

                <Formik
                    initialValues={intialvalues}
                    enableReinitialize
                    validationSchema={ClientImage_Schema}
                    onSubmit={async (values, action) => {

                        let formData = new FormData();

                        formData.append('image', values.image);

                        if (Eid) {
                            setTimeingLoader(true)
                            axios.put("https://api.brainbinaryinfotech.com/satisfiedclientimages/" + Eid, values, {
                                headers: { 'content-type': 'multipart/form-data' }
                            })
                                .then((res) => {
                                    toast.success(res.data.message.toLowerCase())
                                    Get_ClientsImage()
                                    handleClose()
                                    setEid(null)
                                    action.resetForm()
                                    setImagePreview(null)
                                    setTimeingLoader(false)
                                })
                                .catch((err) => {
                                    console.log(err);
                                    setTimeingLoader(false)
                                })
                        }
                        else {
                            setTimeingLoader(true)

                            axios.post("https://api.brainbinaryinfotech.com/satisfiedclientimages/add", values, {
                                headers: { 'content-type': 'multipart/form-data' }
                            })
                                .then((res) => {
                                    toast.success(res.data.message.toLowerCase())
                                    Get_ClientsImage()
                                    handleClose()
                                    action.resetForm()
                                    setImagePreview(null)
                                    setTimeingLoader(false)

                                })
                                .catch((err) => {
                                    toast.error(err.response.data.message.toLowerCase())
                                    console.log(err);
                                    setTimeingLoader(false)
                                })
                        }

                        setintialvalues({ image: '' })
                        action.resetForm()

                    }}
                >
                    {
                        (props) => (
                            <Form>
                                <Modal.Body>

                                    <label htmlFor="Image" className='mb-2'> Image </label>
                                    <div className='mb-3'>
                                        <input onChange={(e) => {
                                            props.setFieldValue("image", e.target.files[0]);
                                            setImagePreview(URL.createObjectURL(e.target.files[0]));
                                        }} type="file" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                    </div>

                                    {imagePreview && (
                                        <div className="mt-3">
                                            <img src={imagePreview} alt="ERROR 404" className='mb-2 bg-secondary rounded px-3' style={{ width: '150px', height: '80px' }} />
                                        </div>
                                    )}
                                    <span> <ErrorMessage name='image' component={'p'} className='text-danger' /> </span>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button style={{ backgroundColor: "#0B618E" }} className="border-0" type='submit'>
                                        {
                                            Eid ? <>  <FaRegSave className="fs-5 me-1" /> Save </> : <> <IoCheckmarkDoneSharp className="fs-5 me-1" /> Submit </>
                                        }
                                    </Button>
                                    <Button style={{ backgroundColor: "#0B618E" }} className="border-0" onClick={() => handleClose()}>  <MdOutlineCancel className="fs-5" /> Cancel</Button>
                                </Modal.Footer>
                            </Form>
                        )
                    }
                </Formik>
            </Modal>

            <div className='d-flex justify-content-between align-items-center px-2'>
                <div className='d-flex'>
                    <h4 style={{ color: "#0B618E" }}> Satisfied Clients ( Slider / Img ) </h4>
                </div>
                <Button variant="primary" className='border-0 d-flex align-items-center' style={{ backgroundColor: "#0B618E" }} onClick={handleShow}>
                    Add Image <FaRegImage className='fs-3 ms-2 p-1' />
                </Button>
            </div>
            <div className='overflow-x-auto px-2'>
                <table className="mt-5 table table-striped" style={{ borderRadius: "10px", overflow: "hidden", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 6px 2px" }}>
                    <thead className='thead-dark'>
                        <tr>
                            <th scope="col" className='text-center' style={{ color: "#0B618E", fontWeight: "normal" }}> ID </th>
                            <th scope="col" className='text-center' style={{ color: "#0B618E", fontWeight: "normal", textWrap: "nowrap" }}> Satisfied Client Img </th>
                            <th scope="col" className='text-center' style={{ color: "#0B618E", fontWeight: "normal" }}> Created AT </th>
                            <th scope="col" className='text-center' style={{ color: "#0B618E", fontWeight: "normal" }}> Updated AT </th>
                            <th scope="col" className='text-center' style={{ color: "#0B618E", fontWeight: "normal" }}> Actions </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ClientImage.map((el, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='text-center align-middle'>{index + 1}</td>
                                        <td className='text-center align-middle'>
                                            <img src={el.image.url} className='bg-secondary p-2 rounded' alt="ERROR 404" style={{ height: "60px", width: "150px" }} />
                                        </td>
                                        <td className='text-center align-middle'> {moment(el.createdAt).format('DD/MM/YYYY')}</td>
                                        <td className='text-center align-middle'> {moment(el.updatedAt).format('DD/MM/YYYY')}</td>
                                        <td className='text-center align-middle'>
                                            <RiDeleteBin5Line onClick={() => Delete_Confirmation(el._id)} className='pointer fs-5 me-3' style={{ color: "red" }} />
                                            <FaRegEdit onClick={() => Edit_ClientImage(el, el._id)} className='pointer fs-5 me-3' style={{ color: "green" }} />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

        </div>
    );
}

export default SetisfiedClientimage;