import React, { useEffect } from 'react';
import axios from 'axios';
// import style from './CompanyHistory.module.css';


function CompanyHistory() {

    const [CompanyHistory, setCompanyHistory] = React.useState([]);

    const Get_CompanyHistory = () => {
        axios.get("https://api.brainbinaryinfotech.com/companyhistory/", {
            headers: { 'content-type': 'multipart/form-data' }
        })
            .then((res) => {
                setCompanyHistory(res.data.HISTORY_DATA);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        Get_CompanyHistory()
    }, [])

    return (
        <div>
            <div className="text-center mx-5 pt-4 px-3">
                <div className="row align-items-center">
                    {
                        CompanyHistory.map((el, index) => {
                            return (
                                <div key={index} className="col-lg-3 col-md-6 col-sm-6 mb-3 mb-sm-0" data-aos="zoom-in">
                                    <div className="card px-3 border-0" style={{ backgroundColor: "transparent" }}>
                                        <div className="card-body d-flex align-items-center">
                                            <div className='pe-3'>
                                                <img src={el.image.url} className='rounded' style={{ width: "70px", height: "70px" }} alt="ERROR 404" />
                                            </div>
                                            <h6 className={`card-text text-start fw-bold`} style={{ color: "#4A5568" }}>
                                                {el.content}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default CompanyHistory