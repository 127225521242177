import React from 'react'
import style from './googlemapflutter.module.css'
import devloper from '../../assets/casestudy/Case-study__image 1.png'
import googlemap from '../../assets/googlemapflutter/googlemap.png'
import googlemapblog from '../../assets/googlemapflutter/googlemap.webp'

const GoogleMapFullter = () => {
    return (
        <div className={`${style.maindiv} container`}>
            <div className={`${style.heading} ${style.headingborder}`} data-aos="fade-right" data-aos-animation="3000">
                How to set marker in Google map in Flutter?
            </div>
            <div className='d-flex mt-5 '>
                <div className={style.devloperinfo}>
                    <img src={devloper} className={`${style.devloperimg}`} alt="..." />
                    <div className='ms-3'>
                        <p className={style.content}>Niyati Brainbinary</p>
                        <p className={style.devloperrole}>Flutter Devloper</p>
                    </div>
                </div>
                <div className='d-flex align-items-center ms-3'>
                    <p className={style.content}>March 12, 2024</p>
                </div>
            </div>
            <div className='mt-5'>
                <img src={googlemap} className={`${style.googlemapimage}`} alt="..." data-aos="zoom-in" />
            </div>
            <div className={`${style.headingcontent} ${style.headingborder} mt-3`} data-aos="fade-right" data-aos-animation="3000">
                Introduction
            </div>
            <div className='mt-3'>
                <p className={style.blogcontent}>Flutter's custom marker feature empowers developers to enhance Google Maps within their Flutter applications by replacing default markers with personalised icons or images. This functionality enables seamless alignment with app branding and user experience goals. Developers can upload custom images or icons, offering creative latitude in denoting points of interest, landmarks, or locations. The feature's intuitive implementation ensures ease of integration and management, allowing developers to update markers efficiently. Custom markers enrich map interactions, improving user engagement and comprehension. In summary, Flutter's custom marker capability enables developers to create visually appealing and informative maps that resonate with their application's identity and functionality.
                </p>
            </div>
            <div className={`${style.headingcontent} ${style.headingborder} mt-3`} data-aos="fade-right" data-aos-animation="3000">
                Table Of Contents :
            </div>
            <div className='mt-3' >
                <ol className={style.listcontent}>
                    <li>Get the API Key</li>
                    <li>Adding Google Maps Flutter package as a dependency</li>
                    <li>Adding an API key for an Android app</li>
                    <li>Adding an API key for an iOS app</li>
                    <li>Create Google Map screen</li>
                    <li>Add Marker to the map</li>
                    <li> Set Custom Image Marker on Map</li>
                </ol>
            </div>
            <div className={`${style.headingcontent} ${style.headingborder} mt-3`} data-aos="fade-right" data-aos-animation="3000">
                Get the API Key
            </div>
            <div className='mt-3'>
                <p className={style.blogcontent}>If you want to use Google Maps in your Flutter application, you’ll need to configure an API project with Google Maps Platform.
                </p>
            </div>
            <div className='mt-3' >
                <ol className={style.listcontent}>
                    <li>Go to the Google Maps Platform {'>'} Create new project or use existing one.</li>
                    <li>On the Library page {'>'} Search for “Maps SDK”.  </li>
                    <li>Click on Maps SDK for iOS and then click Enable. </li>
                    <li>Click Maps SDK for Android and then click Enable</li>
                    <li>On the Credentials page, click Create credentials {'>'} API key. (The API key created dialog displays your newly created API key.)</li>
                    <li>he new API key is listed on the Credentials page under API keys. To rename it click on the edit icon. (Pro tips: Restrict the API key before using it in production.) </li>
                </ol>
            </div>
            <div className={`${style.headingcontent} ${style.headingborder} mt-3`} data-aos="fade-right" data-aos-animation="3000">
                Adding Google Maps Flutter package as a dependency
            </div>
            <div className='mt-3'>
                <p className={style.blogcontent}>In Flutter, packages allow you to add additional functionality. Run this command</p>
                <p className={`${style.blogcontent} mt-2`}>flutter pub add google_maps_flutter</p>
                <p className={`${style.blogcontent} mt-2`}>or add the package under dependencies</p>
                <p className={`${style.blogcontent} mt-3`}>dependencies:</p>
                <p className={`${style.blogcontent} mt-2`}>google_maps_flutter: ^2.2.1</p>
            </div>
            <div className={`${style.headingcontent} ${style.headingborder} mt-3`} data-aos="fade-right" data-aos-animation="3000">
                Adding an API key for an Android app
            </div>
            <div className='mt-3'>
                <p className={style.blogcontent}>To add an API key to the Android app, edit the AndroidManifest.xml file in android/app/src/main. Add a single meta-data entry containing the API key created in the previous step inside the application node. </p>
            </div>
            <div className='mt-3'>
                <p className={style.subheading}>Add API key & permisson to app/main/AndroidManifest.xml </p>
            </div>
            <div className='mt-3'>
                <p className={style.content}>
                    <code >
                        &lt;meta-data<br />
                        &nbsp;&nbsp;android:name="com.google.android.geo.API_KEY"<br />
                        &nbsp;&nbsp;android:value="YOUR KEY HERE" /&gt;
                    </code>
                </p>
            </div>
            <div className='mt-3'>
                <p className={style.subheading}>
                    Also set the minSdkVersion to 21 in android/app/build.gradle
                </p>
            </div>
            <div className='mt-3'>
                <p className={style.content}>
                    <code>
                        android {'{'}
                        <br />
                        &nbsp;&nbsp;defaultConfig {'{'}
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;minSdkVersion 21
                        <br />
                        &nbsp;&nbsp;{'}'}
                        <br />
                        {'}'}
                    </code>
                </p>
            </div>
            <div className={`${style.headingcontent} ${style.headingborder} mt-3`} data-aos="fade-right" data-aos-animation="3000">
                Adding an API key for an iOS app
            </div>
            <div className='mt-3'>
                <p className={style.content} dangerouslySetInnerHTML={{
                    __html: `
            <code>
                import UIKit<br />
                import Flutter<br />
                import GoogleMaps  // Add this import<br /><br />

                @UIApplicationMain<br /><br />

                @objc class AppDelegate: FlutterAppDelegate {<br />
                    override func application(<br />
                        _ application: UIApplication,<br />
                        didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?<br />
                    ) -> Bool {<br />
                        GeneratedPluginRegistrant.register(with: self)<br />
                        // TODO: Add your Google Maps API key<br />
                        GMSServices.provideAPIKey("YOUR-API-KEY")<br />
                        return super.application(application, didFinishLaunchingWithOptions: launchOptions)<br />
                    }<br />
                }
            </code>
        `
                }} />
            </div>
            <div className={`${style.headingcontent} ${style.headingborder} mt-3`} data-aos="fade-right" data-aos-animation="3000">
                Create Google Map screen
            </div>
            <div className='mt-3'>
                <p className={style.blogcontent}>Now it’s time to get a map on the screen. We need an initialCameraPosition to display the map. It can be the user’s current location, but we used a hardcore value for simplicity. </p>
            </div>
            <div className='mt-3'>
                <p className={style.content} dangerouslySetInnerHTML={{
                    __html: `
            <code>
            import 'package:flutter/material.dart';<br />
            import 'package:google_maps_flutter/google_maps_flutter.dart';<br />
            
            
            class MapScreen extends StatefulWidget {<br />
              const MapScreen({super.key});<br />
            @override<br />
              State<MapScreen> createState() => _MapScreenState();<br />
            }<br />
            <br />
            <br />
            class _MapScreenState extends State<MapScreen> {<br />
              LatLng initialLocation = const LatLng(37.422131, -122.084801);<br />
            // ToDo: add custom marker<br />
            @override<br />
            &nbsp;Widget build(BuildContext context) {<br />
            &nbsp;&nbsp;return Scaffold(<br />
            &nbsp;&nbsp;&nbsp; body: GoogleMap(<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;initialCameraPosition: CameraPosition(<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;target: initialLocation,<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;zoom: 14,<br />
                      &nbsp;&nbsp;&nbsp;&nbsp; ),
                    // ToDO: add markers<br />
                    &nbsp;&nbsp;&nbsp;),<br />
                  &nbsp;&nbsp;);<br />
              &nbsp;}<br />
            }<br />
            
            </code><br />
        `
                }} />
            </div>
            <div className={`${style.headingcontent} ${style.headingborder} mt-3`} data-aos="fade-right" data-aos-animation="3000">
                Add Marker to the map
            </div>
            <div className='mt-3'>
                <p className={style.blogcontent}>Markers are useful to identify any specific location.</p>
                <p className={`${style.blogcontent} mt-2`}> Replace To DO: add markers with this 👇</p>
            </div>
            <div className='mt-3'>
                <p className={style.content} dangerouslySetInnerHTML={{
                    __html: `
            <code>
            markers: {<br />
            &nbsp; Marker(<br />
            &nbsp;&nbsp; markerId: const MarkerId("marker1"),<br />
            &nbsp;&nbsp position: const LatLng(37.422131, -122.084801),<br />
            &nbsp;&nbsp draggable: true,<br />
            &nbsp;&nbsp onDragEnd: (value) {<br />
            &nbsp;&nbsp &nbsp;// value is the new position<br />
            &nbsp;&nbsp },<br />
            &nbsp;&nbsp // To do: custom marker icon<br />
            &nbsp;),<br />
            &nbsp; Marker(<br />
            &nbsp;&nbsp; markerId: const MarkerId("marker2"),<br />
            &nbsp;&nbsp; position: const LatLng(37.415768808487435, -122.08440050482749),<br />
            &nbsp;),<br />
              },<br />
              
            </code>
        `
                }} />
            </div>
            <div className={`${style.headingcontent} ${style.headingborder} mt-3`} data-aos="fade-right" data-aos-animation="3000">
                Set Custom Image Marker on Map
            </div>
            <div className='mt-3'>
                <p className={style.blogcontent}>Added a marker image to my assets then create a method that set the markerIcon. </p>
                <p className={`${style.blogcontent} mt-2`}> Replace ToDo: add custom marker with below code 👇</p>
                <p className={`${style.blogcontent} mt-3`}> BitmapDescriptor markerIcon = BitmapDescriptor.defaultMarker;</p>
            </div>
            <div className='mt-3'>
                <p className={style.content} dangerouslySetInnerHTML={{
                    __html: `
            <code>
            @override <br />
            void initState() {<br />
            &nbsp;addCustomIcon();<br />
            &nbsp;super.initState();<br />
            }<br />
            <br />
            void addCustomIcon() {<br />
            &nbsp; BitmapDescriptor.fromAssetImage(<br />
            &nbsp;&nbsp;&nbsp;  const ImageConfiguration(), "assets/Location_marker.png")<br />
            &nbsp;&nbsp;.then(<br />
            &nbsp; (icon) {<br />
            &nbsp;&nbsp; &nbsp;setState(() {<br />
            &nbsp;&nbsp;&nbsp;&nbsp;     markerIcon = icon;<br />
            &nbsp;&nbsp; &nbsp; });<br />
            &nbsp;&nbsp;  },<br />
            &nbsp;);<br />
             }<br />
              
            </code>
        `
                }} />
            </div>
            <div className='mt-3'>
                <p className={style.blogcontent}>first marker which markerId is marker1. Set the icon = markerIcon </p>
            </div>
            <div className='mt-3'>
                <p className={style.content} dangerouslySetInnerHTML={{
                    __html: `
            <code>
            Marker(<br />
            &nbsp; markerId: const MarkerId("marker1"),<br />
            &nbsp; position: const LatLng(37.422131, -122.084801),<br />
            &nbsp;   draggable: true,<br />
            &nbsp;   onDragEnd: (value) {<br />
            &nbsp;   // value is the new position<br />
            &nbsp; },<br />
            &nbsp; icon: markerIcon,<br />
              ),<br />
              <br />
            </code><br />
        `
                }} />
            </div>
            <div className='mt-3 text-center'>
                <img src={googlemapblog} className={style.googlemapimage} alt='Google map' data-aos="zoom-in" />
            </div>
        </div>
    )
}

export default GoogleMapFullter