import React from 'react';
import style from './blogs.module.css';
// import googlemap from '../../assets/googlemapflutter/googlemap.png'
// import statmanagement from '../../assets/statmanagementflutter/statmanagement.png'
// import firebase from '../../assets/firebaselinksflutter/dynamiclinks.png'
import axios from 'axios';
import { useEffect } from 'react';


const Blogs = () => {

    const [Blogs, setBlogs] = React.useState([]);

    const Get_Blogs = () => {
        axios.get("https://api.brainbinaryinfotech.com/blogs/", {
            headers: { 'content-type': 'multipart/form-data' }
        })
            .then((res) => {
                setBlogs(res.data.BLOGS_DATA);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        Get_Blogs()
    }, [])

    return (
        <div className={style.maindiv}>
            <div className='my-xl-5 my-3 py-xxl-4 py-0' data-aos="zoom-in">
                <h1 className={style.heading}>Blogs</h1>
                <div className='d-flex justify-content-center '>
                    <p className={style.blogcontent}>Stay updated with the newest design and development stories and insights shared by BrainBinary Infotech Team.</p>
                </div>
            </div>
            <div className='row mt-lg-5 mt-md-4 mt-3'>

                {
                    Blogs.map((el, index) => {
                        return (
                            <div className='col-lg-4 col-md-6 col-12 p-xxl-4 p-md-2 py-2' key={index} data-aos="fade-up" data-aos-offset="10">
                                <div className={style.backcolor}>
                                    <div className={`card ${style.card}`}>
                                        <img src={el.blog_img.url} className={`card-img-top ${style.cardimage}`} alt="..." />
                                        <div className="card-body">
                                            <h5 className={`card-title ${style.contentheading}`}>
                                                {el.blog_heading}
                                            </h5>
                                            <p className={`card-text ${style.content}`}>
                                                {el.blog_content}
                                            </p>
                                            <a href={index === 0 ? '/googlemap-flutter' :
                                                index === 1 ? '/statmanagement-flutter' :
                                                    index === 2 ? '/firebaselinks-flutter' : '#'} className={`btn ${style.readmorebtn}`}>
                                                Read more &nbsp; &nbsp; &nbsp;
                                                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.172 7.49992L6.808 2.13592L8.222 0.721924L16 8.49992L8.222 16.2779L6.808 14.8639L12.172 9.49992H0V7.49992H12.172Z" fill="url(#paint0_linear_278_569)" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear_278_569" x1="8" y1="0.721924" x2="8" y2="16.2779" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#68B1D2" />
                                                            <stop offset="1" stopColor="#0B618E" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}

export default Blogs;
