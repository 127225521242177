import React from 'react';
import style from './casestudypage.module.css';
import casestudyimage from '../../assets/casestudy/casestudyimage.png';
import CompanyExperience from '../../components/companyexperience/CompanyExperience';
import ContactUs from '../../components/contactus/ContactUs';
import AllCaseStudy from '../../components/allcasestudy/AllCaseStudy';
import roundspring from '../../assets/roundspring.png';
import { Link } from 'react-router-dom';


const CaseStudyPage = () => {
    return (
        <>
            <div className={`${style.maindiv}`}>
                <div className={`${style.rowpadding} row pb-5`}>
                    <div data-aos="zoom-in-right" className="col-lg-7 col-md-6 col-12  pe-xl-5  pe-0 d-flex align-items-center position-relative">
                        <div className={style.wordgroup}>&nbsp;
                            <span className={style.software}>Projects That </span>
                            <span className={style.company}> Speak About  </span>
                            <span className={style.development}>Development </span>
                            <div className='mt-sm-4 mt-2'>
                                <p className={style.content}>We phase our satisfying work out according to an actual results-oriented pattern for a sharp analytical cup of quality, affiliation, timely delivery, and accruable</p>
                            </div>
                            <div className='mt-sm-5 mt-4'>
                                <Link to="/contact-us" className={`btn ${style.getquotebtn} mb-5 text-white`}>
                                    Contact us
                                </Link>
                            </div>
                        </div>
                        <img src={roundspring} className={style.springimage} alt="Springimage" />
                    </div>
                    <div data-aos="zoom-in-left" className="col-lg-5 col-md-6 col-12  ps-xl-5 ps-0">
                        <img src={casestudyimage} alt="" className={`h-100 w-100`} />
                    </div>
                </div>
                <CompanyExperience />
                <AllCaseStudy />
                <ContactUs />
            </div>
        </>
    )
}

export default CaseStudyPage