import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import { FaRegEdit } from "react-icons/fa";
import { FaRegSave } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import LoderPageRound from '../Testing/LoderPageRound';
import AOS from 'aos';
import 'aos/dist/aos.css';
import moment from 'moment';
import { FaUserCheck } from "react-icons/fa6";

function SetisfiedClientCount() {

  const [show, setShow] = useState(false);
  const [SatisfiedClients, setSatisfiedClients] = React.useState([]);
  const [intialvalues, setintialvalues] = React.useState({ clients: '' });
  const [Eid, setEid] = React.useState(null);
  const [loader, Setloader] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Get_SatisfiedClients = () => {
    axios.get("https://api.brainbinaryinfotech.com/satisfiedclientcount/")
      .then((res) => {
        setSatisfiedClients(res.data.CLIENTS);
        Setloader(true)
      })
      .catch((err) => {
        console.log(err);
        Setloader(true)
      })
  }

  const Edit_SatisfiedClients = (value, _id) => {

    setintialvalues(value)
    setEid(_id)
    handleShow()
  }

  useEffect(() => {
    Get_SatisfiedClients()
    AOS.init();
  }, [])


  if (!loader) {
    return <LoderPageRound />
  }

  return (
    <div data-aos="fade-up">

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="d-flex align-items-center">
              <FaUserCheck className="fs-5 me-2" />
              <h6 className="mb-0"> Update Satisfied Clients </h6>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={intialvalues}
          enableReinitialize
          onSubmit={async (values, action) => {

            if (Eid) {
              axios.put("https://api.brainbinaryinfotech.com/satisfiedclientcount/" + Eid, values)
                .then((res) => {
                  toast.success(res.data.message)
                  Get_SatisfiedClients()
                  handleClose()
                  setEid(null)
                  action.resetForm()
                })
                .catch((err) => {
                  console.log(err);
                })
            }

            setintialvalues({ clients: '' })
            action.resetForm()

          }}
        >
          <Form>
            <Modal.Body>
              <label htmlFor="Name" className='mb-2'> Satisfied Clients </label>
              <div className="input-group mb-2">
                <Field name='clients' type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
              </div>
              <span> <ErrorMessage name='clients' component={'p'} className='text-danger' /> </span>
            </Modal.Body>
            <Modal.Footer>
              <Button style={{ backgroundColor: "#0B618E" }} className="border-0" type='submit'> <FaRegSave className="fs-5 me-1" /> Save</Button>
              <Button style={{ backgroundColor: "#0B618E" }} className="border-0" onClick={() => handleClose()}>  <MdOutlineCancel className="fs-5" /> Cancel</Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>

      <div className='d-flex justify-content-between align-items-center px-2'>
        <div className="d-flex">
          <h4 style={{ color: "#0B618E" }}> Satisfied Clients </h4>
        </div>
      </div>
      <div className='overflow-x-auto px-2'>
        <table className="table table-striped my-5" style={{ borderRadius: "10px", overflow: "hidden", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 6px 2px" }}>
          <thead className="thead-dark">
            <tr>
              <th scope="col" className="text-center" style={{ color: "#0B618E", fontWeight: "normal" }}>ID</th>
              <th scope="col" className="text-left" style={{ color: "#0B618E", fontWeight: "normal" }}>Satisfied Clients</th>
              <th scope="col" className="text-left" style={{ color: "#0B618E", fontWeight: "normal" }}>Created AT</th>
              <th scope="col" className="text-left" style={{ color: "#0B618E", fontWeight: "normal" }}>Updated AT</th>
              <th scope="col" className="text-left" style={{ color: "#0B618E", fontWeight: "normal" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              SatisfiedClients.map((el, index) => {
                return (
                  <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                    <td className="text-center align-middle" style={{ padding: "10px" }}>{index + 1}</td>
                    <td className="text-left align-middle" style={{ padding: "10px" }}>{el.clients}</td>
                    <td className="text-left align-middle" style={{ padding: "10px" }}>{moment(el.createdAt).format('DD/MM/YYYY')}</td>
                    <td className="text-left align-middle" style={{ padding: "10px" }}>{moment(el.updatedAt).format('DD/MM/YYYY')}</td>
                    <td className="text-left align-middle" style={{ padding: "10px" }}>
                      <FaRegEdit onClick={() => Edit_SatisfiedClients(el, el._id)} className="pointer fs-5" style={{ color: "green" }} />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SetisfiedClientCount;