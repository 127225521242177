import React from 'react'
import style from './firebaselinksflutter.module.css'
import devloper from '../../assets/casestudy/Case-study__image 1.png'
import firebase from '../../assets/firebaselinksflutter/dynamiclinks.png'
import firebase1 from '../../assets/firebaselinksflutter/firebase1.png'
import firebase2 from '../../assets/firebaselinksflutter/firebase2.png'
import firebase3 from '../../assets/firebaselinksflutter/firebase3.png'
import firebase4 from '../../assets/firebaselinksflutter/firebase4.png'
import firebase5 from '../../assets/firebaselinksflutter/firebase5.png'
import firebase6 from '../../assets/firebaselinksflutter/firebase6.png'
import firebase7 from '../../assets/firebaselinksflutter/firebase7.png'
import firebase8 from '../../assets/firebaselinksflutter/firebase8.png'
import firebase9 from '../../assets/firebaselinksflutter/firebase9.png'
import firebase10 from '../../assets/firebaselinksflutter/firebase10.png'
import firebase11 from '../../assets/firebaselinksflutter/firebase11.png'
import firebase12 from '../../assets/firebaselinksflutter/firebase12.png'

const FireBaseLinksFlutter = () => {
    return (
        <>
            <div className={`${style.maindiv} container`}>
                <div className={`${style.heading} ${style.headingborder}`} data-aos="fade-right" data-aos-animation="3000">
                    How to Implement Firebase Dynamic Links in Flutter?
                </div>
                <div className='d-flex mt-5 '>
                    <div className={style.devloperinfo}>
                        <img src={devloper} className={`${style.devloperimg}`} alt="..." />
                        <div className='ms-3'>
                            <p className={style.content}>Niyati Brainbinary</p>
                            <p className={style.devloperrole}>Flutter Devloper</p>
                        </div>
                    </div>
                    <div className='d-flex align-items-center ms-3'>
                        <p className={style.content}>March 12, 2024</p>
                    </div>
                </div>
                <div className='mt-5'>
                    <img src={firebase} className={`${style.googlemapimage}`} alt="..." data-aos="zoom-in" />
                </div>
                <div className={`${style.headingcontent} ${style.headingborder} mt-3`} data-aos="fade-right" data-aos-animation="3000">
                    Introduction
                </div>
                <div className='mt-3'>
                    <p className={style.blogcontent}>Flutter Firebase Dynamic Links provide users with a seamless experience across platforms by directing them to the appropriate content within Android and iOS applications with a single click.  </p>
                    <p className={`${style.blogcontent} mt-3`}>These dynamic links are versatile, enabling redirection to corresponding web content when accessed via a browser. This approach ensures consistent user engagement regardless of the platform, enhancing overall user experience and driving engagement.
                    </p>
                </div>
                <div className={`${style.headingcontent} ${style.headingborder} mt-3`} data-aos="fade-right" data-aos-animation="3000">
                    How does Flutter Firebase Dynamic Links Work?
                </div>
                <div className='mt-3'>
                    <p className={style.blogcontent}>Flutter Firebase Dynamic links work across applications installed on your platform.</p>
                    <p className={`${style.blogcontent} mt-3`}>If a user opens Firebase Dynamic Links on the Android/iOS platform and doesn’t have your app installed, the user can be redirected to Playstore or AppStore to install it and then, the app starts and the user can access it.
                    </p>
                </div>
                <div className='mt-5 text-center'>
                    <img src={firebase1} className={style.firebaseimage} alt='Firebase' data-aos="zoom-in" />
                </div>
                <div className={`${style.headingcontent} ${style.headingborder} mt-3`} data-aos="fade-right" data-aos-animation="3000">
                    Process to Implement Firebase Dynamic Links in Flutter
                </div>
                <div className='mt-3'>
                    <ol className={style.listcontent}>
                        <li className='h4 text-black'><span className={`${style.subheading} `}> Add Plugin to your flutter project.</span>
                            <div className='mt-3'>
                                <p className={`${style.blogcontent}`}>Inside pubspec.yaml add the following plugin under dependencies and run flutter pub get in the terminal.</p>
                                <p className={`${style.content} mt-3`}>dependencies:</p>
                                <p className={`${style.content} mt-3`}>firebase_dynamic_links: latest</p>
                            </div>
                        </li>
                        <li className='h4 text-black mt-3'>
                            <span className={`${style.subheading} `}>Configure and set up Firebase Project:</span>
                            <div className='mt-3'>
                                <p className={`${style.blogcontent}`}>2.1 Create new firebase project {`(`} <a className={style.link} href="https://console.firebase.google.com/" target="_blank" rel="noopener noreferrer">https://console.firebase.google.com/</a> {`)`}</p>
                            </div>
                            <div>
                                <p className={`${style.listcontent} mt-3`}>Enter the Project name and click the “Continue” button:</p>
                            </div>
                            <div className='mt-5 text-center'>
                                <img src={firebase2} className={style.firebaseimage} alt='Firebase' data-aos="zoom-in" />
                            </div>
                            <div className='mt-5'>
                                <p className={`${style.blogcontent}`}>2.2 After successfully creating a Firebase Project, configure the android app.</p>
                            </div>
                            <div className='mt-2'>
                                <p className={`${style.listcontent} mt-3`}>Enter package name that can be found in you project/android/app/build.gradle</p>
                            </div>
                            <div className='mt-5 text-center'>
                                <img src={firebase3} className={style.firebaseimage} alt='Firebase' data-aos="zoom-in" />
                            </div>
                            <div className='mt-5'>
                                <p className={`${style.blogcontent}`}>2.3 Setup  google-services.json.</p>
                            </div>
                            <div className='mt-2'>
                                <p className={`${style.listcontent} mt-3`}>After successfully adding google-services.json inside android/app, add Firebase Sdk to build.gradle.</p>
                            </div>
                            <div className='mt-5 text-center'>
                                <img src={firebase4} className={style.firebaseimage} alt='Firebase' data-aos="zoom-in" />
                            </div>
                        </li>
                        <li className='h4 text-black mt-3'>
                            <span className={`${style.subheading} `}>Setting up Dynamic Links and add domain:</span>
                            <div className='mt-3'>
                                <p className={`${style.blogcontent}`}>3.1 Select “Dynamic Links” from the console panel then click on “Get Started” button.</p>
                            </div>
                            <div className='mt-5 text-center'>
                                <img src={firebase5} className={style.firebaseimage} alt='Firebase' data-aos="zoom-in" />
                            </div>
                            <div className='mt-5'>
                                <p className={`${style.blogcontent}`}>3.2 Add the URL prefix by entering the domain name. </p>
                            </div>
                            <div className='mt-2'>
                                <p className={`${style.listcontent} mt-3`}>Here firebase will automatically provide some domains. You can edit the domain as you like and click the “Continue” button.</p>
                            </div>
                            <div className='mt-5 text-center'>
                                <img src={firebase6} className={style.firebaseimage} alt='Firebase' data-aos="zoom-in" />
                            </div>
                        </li>
                        <li className='h4 text-black mt-3'>
                            <span className={`${style.subheading} `}>Create New Dynamic Link :</span>
                            <div className='mt-3'>
                                <p className={`${style.blogcontent}`}>4.1 Click the “New Dynamic Link” button.</p>
                            </div>
                            <div className='mt-5 text-center'>
                                <img src={firebase7} className={style.firebaseimage} alt='Firebase' data-aos="zoom-in" />
                            </div>
                            <div className='mt-5'>
                                <p className={`${style.blogcontent}`}>4.2 Setup URL prefix. Here firebase will automatically provide some name after the URL. </p>
                            </div>
                            <div className='mt-2'>
                                <p className={`${style.listcontent} mt-3`}>You can edit that or keep it as it is and click the “Next” button.</p>
                            </div>
                            <div className='mt-5 text-center'>
                                <img src={firebase8} className={style.firebaseimage} alt='Firebase' data-aos="zoom-in" />
                            </div>
                            <div className='mt-5'>
                                <p className={`${style.blogcontent}`}>4.3 Set play store link inside ”Deep link Url” field for if a user has not installed the app.</p>
                            </div>
                            <div className='mt-2'>
                                <p className={`${style.listcontent} mt-3`}>Then the user will be redirected to the play store. Enter “Dynamic Link name”.</p>
                            </div>
                            <div className='mt-5 text-center'>
                                <img src={firebase9} className={style.firebaseimage} alt='Firebase' data-aos="zoom-in" />
                            </div>
                            <div className='mt-5'>
                                <p className={`${style.blogcontent}`}>4.4 If you want to open a URL in a browser select the “Open the deep link URL in a browser” option.</p>
                            </div>
                            <div className='mt-2'>
                                <p className={`${style.listcontent} mt-3`}>If not, select another option.</p>
                            </div>
                            <div className='mt-5 text-center'>
                                <img src={firebase10} className={style.firebaseimage} alt='Firebase' data-aos="zoom-in" />
                            </div>
                            <div className='mt-5'>
                                <p className={`${style.blogcontent}`}>4.5. Android app setup:</p>
                            </div>
                            <div className='mt-2'>
                                <p className={`${style.listcontent} mt-3`}>Select “Open the deep link in your Android App” and click the Next button.</p>
                            </div>
                            <div className='mt-5 text-center'>
                                <img src={firebase11} className={style.firebaseimage} alt='Firebase' data-aos="zoom-in" />
                            </div>
                            <div className='mt-5 text-center'>
                                <img src={firebase12} className={style.firebaseimage} alt='Firebase' data-aos="zoom-in" />
                            </div>
                        </li>
                        <li className='h4 text-black mt-5'>
                            <span className={`${style.subheading} `}> Setup AndroidManifest.xml(Optional)</span>
                            <div className='mt-2'>
                                <p className={`${style.blogcontent}`}>If you want to open app directly without being redirected to the browser, add the following lines of code inside android/app/src/main/AndroidManifest.xml</p>
                            </div>
                            <div className='mt-3'>
                                <p className={style.content}>
                                    <code>
                                        &lt;intent-filter&gt;<br />
                                        &nbsp; &nbsp;  &lt;action android:name="android.intent.action.VIEW" /&gt;<br />
                                        &nbsp; &nbsp;  &lt;category android:name="android.intent.category.DEFAULT" /&gt;<br />
                                        &nbsp; &nbsp;  &lt;category android:name="android.intent.category.BROWSABLE" /&gt;<br />
                                        &nbsp; &nbsp; &lt;!-- Accepts URIs that begin with YOUR_SCHEME://YOUR_HOST --&gt;<br />
                                        &nbsp; &nbsp; &lt;data<br />
                                        &nbsp; &nbsp;  &nbsp; android:scheme="https"<br />
                                        &nbsp; &nbsp;  &nbsp; android:host="https://itoneclick.page.link" /&gt;<br />
                                        &lt;/intent-filter&gt;<br />
                                    </code>
                                </p>
                            </div>
                        </li>
                        <li className='h4 text-black mt-3'>
                            <span className={`${style.subheading} `}>  Create a Dynamic Link Inside Your App Using the Following Lines:</span>
                            <div className='mt-3'>
                                <p className={`${style.blogcontent}`}>To create a dynamic link we have to add title, image and itemId.
                                </p>
                            </div>
                            <div className='mt-3'>
                                <p className={`${style.blogcontent}`}>Here I have added “id = itemId” after the link to identify specific items while handling dynamic links.   </p>
                            </div>
                            <div className='mt-3'>
                                <p className={style.content} dangerouslySetInnerHTML={{
                                    __html: `
                                    <code>
                                    Future createDynamicLink(<br />
                                     &nbsp;   BuildContext context,<br />
                                     &nbsp;  String title,<br />
                                     &nbsp;   String image,<br />
                                     &nbsp;   String itemId,<br />
                                      ) async {<br />
                                      &nbsp;  bool short = true;<br />
                                      &nbsp;final DynamicLinkParameters parameters = DynamicLinkParameters(<br />
                                      &nbsp;  //add urlPrefix as per point 3.2<br />
                                      &nbsp;  uriPrefix: 'https://itoneclick.page.link',<br />
                                      &nbsp;  //add link as per point 4.7<br />
                                      &nbsp;  link: Uri.parse('https://itoneclick.page.link/testing/?id=$itemId'),<br />
                                      &nbsp;  androidParameters: AndroidParameters(<br />
                                      &nbsp; &nbsp;   //android/app/build.gradle<br />
                                      &nbsp; &nbsp;  packageName: 'android package name',<br />
                                      &nbsp; &nbsp;  minimumVersion: 0,<br />
                                      &nbsp;  ),<br />
                                      &nbsp;    socialMetaTagParameters:<br />
                                      &nbsp; &nbsp; &nbsp;     SocialMetaTagParameters(title: title, imageUrl: Uri.parse(image)),<br />
                                      &nbsp;  dynamicLinkParametersOptions: DynamicLinkParametersOptions(<br />
                                      &nbsp; &nbsp;   shortDynamicLinkPathLength: ShortDynamicLinkPathLength.short,<br />
                                      &nbsp; ),<br />
                                      &nbsp;  iosParameters: IosParameters(<br />
                                      &nbsp;&nbsp;  bundleId: 'ios bundleId',<br />
                                      &nbsp;&nbsp;  minimumVersion: '0',<br />
                                      &nbsp; ),<br />
                                        );<br />
                                        <br />
                                        Uri url;<br />
                                        if (short) {<br />
                                        &nbsp; final ShortDynamicLink shortLink = await parameters.buildShortLink();<br />
                                        &nbsp; url = shortLink.shortUrl;<br />
                                        } else {<br />
                                        &nbsp; url = await parameters.buildUrl();<br />
                                        }<br />
                                        <br />
                                        &nbsp;return url.toString();<br />
                                      }<br />
                                      
                                    </code>
                                `
                                }} />
                            </div>
                        </li>
                        <li className='h4 text-black mt-3'>
                            <span className={`${style.subheading} `}>Handle Dynamic Link:</span>
                            <div className='mt-3'>
                                <p className={`${style.blogcontent}`}>To receive a firebase dynamic link call, getInitiaLink(). </p>
                                <p className={`${style.blogcontent} mt-3`}>getInitiaLink() will return the URL that opened the app, otherwise, it will return null.   </p>
                                <p className={`${style.blogcontent} mt-3`}>If the dynamic link is not null you can use the navigator inside the following method.   </p>
                            </div>
                            <div className='mt-3'>
                                <p className={style.content} dangerouslySetInnerHTML={{
                                    __html: `
                                    <code>
                                    Future<void> initDynamicLinks() async {<br />
                                       &nbsp; FirebaseDynamicLinks.instance.onLink(<br />
                                       &nbsp; &nbsp; &nbsp;  onSuccess: (PendingDynamicLinkData dynamicLink) async {<br />
                                       &nbsp; &nbsp;  final Uri deepLink = dynamicLink.link;<br />
                                          <br />
                                          &nbsp; &nbsp;  if (deepLink != null) {<br />
                                          &nbsp; &nbsp; &nbsp; //We have added id parameter while creating dynamic link<br />
                                          &nbsp; &nbsp; &nbsp; //It will help you to find specific item from list and<br />
                                          &nbsp; &nbsp; &nbsp;String id = deepLink.queryParameters['id'];<br />
                                          &nbsp; &nbsp; &nbsp; print(id);<br />
                                          &nbsp; &nbsp;}<br />
                                          &nbsp; }, onError: (OnLinkErrorException e) async {<br />
                                          &nbsp; &nbsp; print(e.message);<br />
                                          &nbsp; });<br />
                                        <br />
                                      //To Handle pending dynamic links add following lines<br />
                                      &nbsp; final PendingDynamicLinkData data =<br />
                                      &nbsp; &nbsp; await FirebaseDynamicLinks.instance.getInitialLink();<br />
                                            &nbsp; final Uri deepLink = data?.link;<br />
                                        <br />
                                        &nbsp; if (deepLink != null) {<br />
                                        &nbsp; &nbsp; String id = deepLink.queryParameters['id'];<br />
                                        &nbsp; &nbsp; print(id);<br />
                                        &nbsp; }<br />
                                      }<br />
                                      
                                      
                                    </code>
                                `
                                }} />
                            </div>
                            <div className='mt-3'>
                                <p className={`${style.blogcontent}`}>So, here we end up with successful creation and handle firebase dynamic links using Flutter and Firebase. </p>
                            </div>
                        </li>
                    </ol>
                </div>

            </div>
        </>
    )
}

export default FireBaseLinksFlutter