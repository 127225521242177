// Frontend - User View //

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Layout from './components/layout/Layout';
import Home from './pages/home/Home';
import GoogleMapFullter from './pages/googlemapflutter/GoogleMapFullter';
import StatManagementFlutter from './pages/statmanagementflutter/StatManagementFlutter';
import FireBaseLinksFlutter from './pages/firebaselinksflutter/FireBaseLinksFlutter';
import BlogsPage from './pages/blogspage/BlogsPage';
import CaseStudyPage from './pages/casestudypage/CaseStudyPage';
import AboutUs from './pages/aboutus/AboutUs';
import Careears from './pages/careears/Careears';
import ReactJs from './pages/reactjs/ReactJs';
import Flutter from './pages/flutter/Flutter';
import Android from './pages/Android/Android';
import Iso from './pages/iso/Iso';
import NodeJs from './pages/nodejs/NodeJs';
import UiUx from './pages/uiux/UiUx';
import FullStack from './pages/fullstack/FullStack';
import WebDevlopment from './pages/web-devlopment/WebDevlopment';
import AppDevlopment from './pages/app-devlopment/AppDevlopment';
import ModernizationServices from './pages/modernization-services/ModernizationServices';
import ItConsulting from './pages/it-consulting/ItConsulting';
import ItStaff from './pages/it-staff/ItStaff';
import ProductEngineearing from './pages/product-engineearing/ProductEngineearing';
import OutSourceCompany from './pages/outsorce-company/OutSourceCompany';
import ScrollToTop from './components/scrollontop/ScrollToTop';
import DigitalMarketing from './pages/digital-marketing/DigitalMarketing';
import Service from './pages/service/Service';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ContactUsPage from './pages/contactus/ContactUsPage';

// Frontend - Admin Panel //

import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminLogin from './adminpages/AdminLogin/AdminLogin';
import AdminPanel from './admincomponents/AdminPanel/AdminPanel';
import CategoriesTech from './adminpages/OurTechnologies/CategoriesTech';
import ItemsTech from './adminpages/OurTechnologies/ItemsTech';
import DashboardPage from './adminpages/Dashboard/DashboardPage';
import GetQuotes from './adminpages/GetQuotes/GetQuotes';
import SecureRouter from './admincomponents/SecureRouter/SecureRouter';
import Blogs from './adminpages/Blogs/Blogs';
import CaseStudy from './adminpages/CaseStudy/CaseStudy';
import CompanyHistory from './adminpages/CompanyHistory/CompanyHistory';
import SetisfiedClientCount from './adminpages/ClientsCustomers/SetisfiedClientCount';
import SetisfiedClientimage from './adminpages/ClientsCustomers/SetisfiedClientimage';
import OurCustomersLove from './adminpages/ClientsCustomers/OurCustomersLove';
import CurrentOpenings from './adminpages/CurrentOpenings/CurrentOpenings';
import ApplyJobNow from './adminpages/ApplyJobNow/ApplyJobNow';
import OurService from './adminpages/OurService/OurService';
import RecentlyLaunched from './adminpages/RecentlyLaunched/RecentlyLaunched';




function App() {
  useEffect(() => {
    AOS.init();

    const handleScroll = () => {
      AOS.refresh();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>

          {/* Frontend User Route */}

          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/googlemap-flutter" element={<GoogleMapFullter />} />
            <Route path="/statmanagement-flutter" element={<StatManagementFlutter />} />
            <Route path="/firebaselinks-flutter" element={<FireBaseLinksFlutter />} />
            <Route path="/blogs" element={<BlogsPage />} />
            <Route path="/case-study" element={<CaseStudyPage />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/careears" element={<Careears />} />
            <Route path="/reactjs" element={<ReactJs />} />
            <Route path="/flutter" element={<Flutter />} />
            <Route path="/android" element={<Android />} />
            <Route path="/iso" element={<Iso />} />
            <Route path="/nodejs" element={<NodeJs />} />
            <Route path="/ui-ux" element={<UiUx />} />
            <Route path="/full-stack" element={<FullStack />} />
            <Route path="/web-devlopment" element={<WebDevlopment />} />
            <Route path="/app-devlopment" element={<AppDevlopment />} />
            <Route path="/modernization-services" element={<ModernizationServices />} />
            <Route path="/consulting-services" element={<ItConsulting />} />
            <Route path="/staff-augmentation" element={<ItStaff />} />
            <Route path="/product-engineering" element={<ProductEngineearing />} />
            <Route path="/outsorce-company" element={<OutSourceCompany />} />
            <Route path="/digital-marketing" element={<DigitalMarketing />} />
            <Route path="/service" element={<Service />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
          </Route>

          {/* Frontend Admin Panel Route */}

          <Route path="/admin/login" element={<AdminLogin />} />

          <Route element={
            <SecureRouter>
              <AdminPanel />
            </SecureRouter>
          } >

            <Route path="/admin/dashboard" element={<DashboardPage />} />
            <Route path="/admin/ourtechnologies/categories" element={<CategoriesTech />} />
            <Route path="/admin/ourtechnologies/items" element={<ItemsTech />} />
            <Route path="/admin/getquote" element={<GetQuotes />} />
            <Route path="/admin/blogs" element={<Blogs />} />
            <Route path="/admin/casestudy" element={<CaseStudy />} />
            <Route path="/admin/companyhistory" element={<CompanyHistory />} />
            <Route path="/admin/clientscustomers/satisfiedclientcount" element={<SetisfiedClientCount />} />
            <Route path="/admin/clientscustomers/satisfiedclientimage" element={<SetisfiedClientimage />} />
            <Route path="/admin/clientscustomers/ourcustomerlove" element={<OurCustomersLove />} />
            <Route path="/admin/currentopenings" element={<CurrentOpenings />} />
            <Route path="/admin/applyjobnow" element={<ApplyJobNow />} />
            <Route path="/admin/ourservice" element={<OurService />} />
            <Route path="/admin/recentlylaunched" element={<RecentlyLaunched />} />

          </Route>

        </Routes>

      </BrowserRouter>

      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition={Bounce}
      />

    </>
  );
}

export default App;
